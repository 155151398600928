import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/iPhone X Mockup1 (2).png';
import pic2 from '../assets/images/Iphone 11 Screen1.png';
import pic3 from '../assets/images/Pintrest Mockup.png';
import pic4 from '../assets/images/Macbook3.png';
import pic5 from '../assets/images/musicappDS.png';
import pic6 from '../assets/images/mortgageDS.png';
import pic7 from '../assets/images/Chunglogo.png';
import pic8 from '../assets/images/Cherenlogo.png';
import pic9 from '../assets/images/PoshDS.png';
import pic10 from '../assets/images/medicalDS.png';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          {/* <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li> */}
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            "I strive for two things in design: simplicity and clarity. Great
            design is born of those two things."
          </h2>
          <p>Lindon Leader</p>
        </header>
        {/* <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul> */}
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <h1 className="case">Case Studies</h1>
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>Blush</h2>
          <p>
            Mobile app that helps partners communicate their intimate desires in
            a safe and fun environment.
          </p>
          <div className="button__box">
            <a
              href="https://www.figma.com/proto/1K2eedC2mE9lb4HJKeT7z9/Capstone-Task-Flow?node-id=361%3A424&scaling=scale-down&page-id=335%3A220&starting-point-node-id=361%3A424"
              target="_blank"
              className="button fit"
            >
              View Prototype
            </a>
          </div>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>Wildlife Alliance</h2>
          <p>
            A 5-day design sprint of an iPhone app design with an end-to-end
            animal sponsorship experience for “The Wildlife Alliance”.
          </p>
          <a
            href="https://www.figma.com/proto/nBK37DexnQrs427rBS6GCq/WildLife-Alliance-Design-Sprint?scaling=scale-down&page-id=0%3A1&node-id=17%3A2&starting-point-node-id=17%3A2"
            target="_blank"
            className="button fit"
          >
            View Prototype
          </a>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>Poshmark</h2>
          <p>
            Identified Heuristic evaluations within a task flow of the Poshmark
            mobile app and redesigned it to rectify the violations that were
            identified.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic4} alt="" />
        </div>
        <div className="content">
          <h2>Safer</h2>
          <p>
            A Google hackathon partnered with BrainStation completed within a 24
            hour timeframe we created Safer, a website that educates users on
            data privacy.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Design Challenges</h2>
          <p>
            Individual visual design challenges: designing small elements or
            pages.
          </p>
        </header>
        <ul className="features">
          <li className="icon">
            <div className="design__box">
              <img src={pic5} alt="test" className="design__phone" />
            </div>
          </li>
          <li className="icon ">
            <div className="design__box">
              <img src={pic10} alt="test" className="design__phone" />
            </div>
          </li>
          <li className="icon ">
            <div className="design__box">
              <img src={pic7} alt="test" className="design__back" />
            </div>
          </li>
          <li className="icon ">
            <div className="design__box">
              <img src={pic8} alt="test" className="design__back" />
            </div>
          </li>
          <li className="icon ">
            <div className="design__box">
              <img src={pic9} alt="test" className="design" />
            </div>
          </li>
          <li className="icon ">
            <div className="design__box">
              <img src={pic6} alt="test" className="design" />
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>If you made it this far!</h2>
          <p>
            Please make sure to check out my resume and learn about my story!
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/resume" className="button fit primary">
              Resume
            </a>
          </li>
          <li>
            <a href="/mystory" className="button fit">
              My Story
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
